<template>
  <el-row class="device">
    <el-col :span="6" class="device-item">
      <div class="device-item-content add" @click="handleBinddevice">
        <span><i class="el-icon-plus"></i> 绑定设备</span>
      </div>
    </el-col>
    <el-col :span="6" v-for="(device, key) in devices" class="device-item" :key="key">
      <div class="device-item-content">
        <p class="name">
          <span class="label">设备名称:</span>
          <span class="value">{{device.deviceName}}</span>
        </p>
        <p>
          <span class="label">设备编号:</span>
          <span>{{device.uuid}}</span>
        </p>
        <p><span class="label">产品名称:</span> <span>{{device.productName}}</span></p>
        <p>
          <span class="label">设备状态:</span>
          <span>在线</span>
        </p>
        <p class="unbind" ><i
          class="iconfont iconjiebang"></i> 解绑</p>
      </div>
    </el-col>

  </el-row>
</template>

<script>
  export default {
    name: 'device',
    components: {},
    mounted: function () {

    },
    data: function () {
      return {
        devices: [
          {
            deviceName: '测试样机',
            productName: '咖博士F11',
            uuid: '6fc77f81f001000',
          },
          {
            deviceName: '测试样机',
            productName: '咖博士F11',
            uuid: '6fc77f81f001000',
          },
          {
            deviceName: '测试样机',
            productName: '咖博士F11',
            uuid: '6fc77f81f001000',
          },
          {
            deviceName: '测试样机',
            productName: '咖博士F11',
            uuid: '6fc77f81f001000',
          }
        ],
        deviceUuids: [],
        binddeviceVisible: false
      }
    },
    methods: {
      handleBinddevice() {
        this.deviceUuids = []
        this.devices.forEach(item => {
          this.deviceUuids.push(item.deviceUuid)
        })
        this.binddeviceVisible = true
      },

      showdeviceExtend(device, index) {
        this.device.month = 1
        this.device.index = index
        this.device.companyUuid = this.company.uuid
        this.device.deviceUuid = device.deviceUuid
        this.dialogdeviceVisible = true
      },
      showUseCondition() {
        // companydeviceCondition({
        //   companyUuid: this.company.uuid,
        //   deviceUuid: device.deviceUuid
        // }).then(res => {
        //   this.deviceDrinks = res.data || []
        //   this.showdeviceDrinkVisible = true
        // })
      },
      showBindDevice(combinator) {
        this.bind.uuid = combinator.deviceUuid
        this.bindVisible = true
        let vue = this

        this.$nextTick(function () {
          this.$refs.devices.loadDevices(vue.bind.name)
        })
      },
      showHasBindDevice() {
        // companydeviceDevices({uuid: uuid}).then(res => {
        //   this.devices = res.data || []
        //   this.showDeviceVisible = true
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .device {
    .device-item {
      padding: 0 0 $small-space $small-space;


      .add {
        cursor: pointer;
        color: $theme-color;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
      }

      &-content {
        height: 104px;
        border-radius: $box-radius;
        border: 1px solid;
        padding: $middle-space;
        font-size: 13px;
        position: relative;
        cursor: pointer;

        .unbind {
          position: absolute;
          top: 0;
          right: $small-space;
          margin-top: $small-space;
          cursor: pointer;
          color: $theme-color;
        }

        .label {
          // @include set_font_color($--font-02-color, $--font-color-dark);
          margin-right: 5px;
        }

        .value {
          font-weight: 500;
          display: inline-block;
          width: 120px;
        }

        .device-btn {

          display: flex;

          div {
            flex: 1;
            text-align: center;
            font-weight: 500;
            margin-top: 8px;
            border-right: 2px solid $color-base;
          }

          div:last-of-type {
            border: none;
          }

          div:hover {
            color: $theme-color;
            cursor: pointer;
            border-color: $color-base;
          }
        }
      }

      &-content:hover{
        border-color: $theme-color;
      }
    }
  }
</style>
